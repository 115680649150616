.ring {
  display: block;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 60px auto;
  position: relative;
  z-index: 1000;
  border-top: 3px solid #d9d9d9;
  border-right: 3px solid #d9d9d9;
  border-bottom: 3px solid #d9d9d9;
  border-left: 3px solid #148fcc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loading 0.9s infinite linear;
  animation: loading 0.9s infinite linear;
}

.ring::after {
  display: block;
  content: "";
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
